import { Hero, Button } from 'react-daisyui'

function App() {
  return (
    <>
      <Hero className='bg-base-200 min-h-[30rem] text-center flex flex-row justify-center'>
        <Hero.Content className='max-w-2xl flex flex-col'>
          <h1 className="text-5xl font-bold">Boost Your Blog's Visibility with Semantic SEO</h1>
          <p className="py-6">WPSemantic is a no-code solution that will help you easily apply advanced SEO techniques to your blog, improving search engine understanding.</p>

          <Button color="primary">Start Now</Button>
        </Hero.Content>
      </Hero>
    </>
  );
}

export default App;
